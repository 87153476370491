import { ImQuotesRight } from 'react-icons/im';

const TestimonialCard = ({ name, text, img, imgAlt, AOSdelay }) => {
  return (
    <div data-aos='fade-up' data-aos-duration='800' data-aos-delay={AOSdelay}>
      <div className='bg-transparent hover:bg-card rounded-sm border-t-8 border-transparent hover:border-primary flex flex-col text-center items-center justify-center trans p-4'>
        <div className='relative'>
          <ImQuotesRight
            className='absolute right-0 fill-current text-white bg-primary p-1.5 rounded-full'
            size='2em'
          />
          <img
            src={img}
            alt={imgAlt}
            className='h-20 rounded-full p-1.5 mt-2'
          />
        </div>
        <div className='space-y-2 pt-4'>
          <h4 className='text-xl font-semibold'>{name}</h4>
          <p className='text-cardText w-full md:w-3/4 xl:w-full mx-auto'>
            {text}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
