const Refund = () => {
  return (
    <div className='flex flex-col min-h-screen overflow-hidden text-white bg-background space-y-6 p-8'>
      <h1 className='text-primary text-3xl font-bold'>Refund Policy</h1>
      <p>
        We do not offer any refunds/returns once the order is confirmed and the
        product is received, due to our service being digital-based.{' '}
      </p>
      <p>
        We recommend contacting us for assistance if you experience any issues
        receiving our products.
      </p>
      <h2 className='text-primary text-xl font-semibold'>Contact us</h2>
      <p>
        If you have any questions about our Returns and Refunds Policy, please
        contact us by our email at business.goodchefs@gmail.com.
      </p>
    </div>
  );
};

export default Refund;
