import { BiHash, BiLinkAlt, BiRefresh } from 'react-icons/bi';
import { HiPencilAlt } from 'react-icons/hi';
import { RiSpeedFill, RiDoorOpenFill } from 'react-icons/ri';

const features = [
  {
    name: 'RELEASE CHANNELS',
    description:
      'Individualized channels dedicated to every profitable release containing all the information you need',
    icon: BiHash,
    AOSdelay: '0',
  },
  {
    name: 'HIGH SPEED MONITORS',
    description:
      "Access to diverse monitors that are the fastest available, to ensure you don't miss a single restock",
    icon: RiSpeedFill,
    AOSdelay: '200',
  },
  {
    name: 'EARLY LINKS',
    description:
      'Early access to product links before the product drop, on sites like Kith',
    icon: BiLinkAlt,
    AOSdelay: '400',
  },
  {
    name: 'PREORDERS/BACKDOORS',
    description:
      'Access to exclusive preorders and backdoors for the hottest sneaker releases',
    icon: RiDoorOpenFill,
    AOSdelay: '600',
  },
  {
    name: 'Autofill bot',
    description:
      'Blazing fast auto fill tool free for use by all members. Cop items for retail quick and easy. Supported sites include Supreme, Shopify, Walmart, and much more!',
    icon: HiPencilAlt,
    AOSdelay: '800',
  },
  {
    name: 'Lowkey flips',
    description:
      'Exclusive private information about profitable flips that are not saturated',
    icon: BiRefresh,
    AOSdelay: '1000',
  },
];

const Features = () => {
  return (
    <div className=''>
      <div className='space-y-3 text-center flex flex-col justify-center'>
        <h2
          className='text-4xl md:text-5xl font-bold tracking-tight'
          data-aos='fade-up'
          data-aos-duration='800'
          data-aos-delay='0'
        >
          Features
        </h2>
        {/* <p
          className='text-cardText lg:w-1/3 px-3 mx-auto'
          data-aos='fade-up'
          data-aos-duration='800'
          data-aos-delay='300'
        >
          Our staff team is comprised of experienced resellers, providing
          personalized support to all members
        </p> */}
      </div>

      <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 pt-10'>
        {features.map((feature) => (
          <div
            key={feature.name}
            data-aos='fade-up'
            data-aos-duration='800'
            data-aos-delay={feature.AOSdelay}
          >
            <div className='bg-transparent hover:bg-card rounded-sm border-t-8 border-transparent hover:border-primary flex flex-col text-center items-center justify-center space-y-4 trans p-8 h-full'>
              <div className='bg-primary bg-opacity-40 text-primary p-3 rounded-full'>
                <feature.icon
                  className='fill-current text-primary'
                  size='2em'
                  color='white'
                  aria-hidden='true'
                />
              </div>
              <p className='text-xl font-semibold uppercase'>{feature.name}</p>
              <dd className='text-cardText'>{feature.description}</dd>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
