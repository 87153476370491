import TestimonialCard from './components/TestimonialCard';

const Testimonials = () => {
  return (
    <div className=''>
      <div className='space-y-3 text-center flex flex-col justify-center'>
        <h2
          className='text-4xl md:text-5xl font-bold tracking-tight'
          data-aos='fade-up'
          data-aos-duration='800'
          data-aos-delay='0'
        >
          Testimonials
        </h2>
        {/* <p
          className='text-cardText lg:w-1/3 px-3 mx-auto'
          data-aos='fade-up'
          data-aos-duration='800'
          data-aos-delay='300'
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus eius
          corrupti maxime, quos molestiae aliquid voluptate ex fugit iste quis
          itaque quia quidem saepe, vitae architecto rerum ducimus delectus
          totam!
        </p> */}
      </div>

      <div className='grid grid-cols-1 xl:grid-cols-3 gap-8 pt-10'>
        <TestimonialCard
          AOSdelay='0'
          img='https://cdn.discordapp.com/attachments/784637379830218752/841517702731399188/b19ff1a7ed8ca373f3972f96d2f37d2e.png'
          imgAlt='612 Profile Picture'
          name='613outlet'
          text='This is my second testimony but I felt it was only fair to make another after my life completely started to change. I have been reselling since early 2018 and never really made a lot from it but once I joined the team in November and my sales instantly transformed from $500 a month to $3000+. This group is genuinely amazing, the information in here and the experts are so many various topics is just mind blowing. I went from not know a thing about sports cards to becoming a long term investor in them! This group also helped me secure my first 2 bots for retail and half of retail! (ECB TSB). Lastly it’s not only the services and opportunities this group creates in so many different sectors and ways but they also create such a healthy and motivating environment for the members in this server where when we see each other grow we don’t get jealous, we get motivated! Thank you for everything TGC!'
        />
        <TestimonialCard
          AOSdelay='200'
          img='https://cdn.discordapp.com/attachments/784637379830218752/841517837125681152/ce02da620dc1684d006741142bd4eeb1.png'
          imgAlt='twenty Profile Picture'
          name='1twenty8i'
          text='Shoutout to the staff that continues to supply the good chefs with these preorders. working crazy to get through 700+ tickets and counting. it’s crazy the prices they get and takes almost no effort to fill out the form and just straight profit. if you take full advantage of what this group has to offer you are practically guaranteed to make money. you can open a ticket at any time of the day or even night and someone will be there to help you. even for the members that aren’t staff, i have gotten just as much advice and help for the community as staff. thank you to everyone in here and can’t wait to make 2021 a movie!'
        />
        <TestimonialCard
          AOSdelay='400'
          img='https://cdn.discordapp.com/attachments/784637379830218752/841517580245139456/7fb725647f13f1bd2d63ccd9ac6416e2.png'
          imgAlt='vick Profile Picture'
          name='*vick-'
          text='Here, I’ve been provided a home. Somewhere where I feel loved and appreciated. Before joining TheGoodChefs I was just “dipping my feet in the water” and I was only going for Supreme. This group really enhanced my understanding of how to cop each and every release. I’ve never been happier to be somewhere. I was once extremely depressed, and drove myself 15,000$+ into debt. Thanks to TheGoodChefs I’ve been out of debt for a few months now and can happily say I’ll never be going back into debt. I’ve made a plethora of connections, not only as business connections but lifetime friendships with many. I can’t wait to see what 2021 has to bring for this group. Cheers to everyones success in here. Always astonished by what can be accomplished in a cook group'
        />
      </div>
    </div>
  );
};

export default Testimonials;
