import './App.css';

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';

import Index from './pages/index';
import Privacy from './pages/Privacy';
import Refund from './pages/Refund';
import Error from './pages/404';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/' component={Index} />
        <Route exact path='/privacy' component={Privacy} />
        <Route exact path='/refund' component={Refund} />
        <Route exact path='/404' component={Error} />
        <Redirect to='/404' />
      </Switch>
    </Router>
  );
}

export default App;
