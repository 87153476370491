import Navbar from '../sections/Navbar';
import Hero from '../sections/Hero';
import Features from '../sections/Features';
import Faq from '../sections/Faq';
import Testimonials from '../sections/Testimonials';
import Footer from '../sections/Footer';
import Particles from 'react-particles-js';
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <Particles id='particles-js' />
      <div className='flex flex-col min-h-screen overflow-hidden text-white bg-background'>
        <div className='container mx-auto px-4 space-y-36 relative'>
          <Navbar />
          <Hero />
          <Features />
          <Faq />
          <Testimonials />
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
