const Privacy = () => {
  return (
    <div className='flex flex-col min-h-screen overflow-hidden text-white bg-background space-y-6 p-8'>
      <h1 className='text-primary text-3xl font-bold'>
        ONLINE PRIVACY POLICY AGREEMENT
      </h1>
      <p>
        GOOD CHEFS LLC, is committed to keeping any and all personal information
        collected of those individuals that visit our website and make use of
        our online facilities and services accurate, confidential, secure and
        private. Our privacy policy has been designed and created to ensure
        those affiliated with GOOD CHEFS LLC of our commitment and realization
        of our obligation not only to meet but to exceed most existing privacy
        standards. THEREFORE, this Privacy Policy Agreement shall apply to GOOD
        CHEFS LLC , and thus it shall govern any and all data collection and
        usage thereof. Through the use of GOOD CHEFS LLC you are herein
        consenting to the following data procedures expressed within this
        agreement.{' '}
      </p>
      <p>Collection of Information</p>
    </div>
  );
};

export default Privacy;
