// import { AiFillPlayCircle } from 'react-icons/ai';
// import { Dialog, Transition } from '@headlessui/react';
// import { Fragment, useRef, useState } from 'react';

import IntroVid from '../images/intro.mp4';
import Thumbnail from '../images/thumbnail.png';

const Hero = () => {
  //   const [open, setOpen] = useState(false);
  //   const cancelButtonRef = useRef();
  //   function closeModal() {
  //     setOpen(false);
  //   }
  //   function openModal() {
  //     setOpen(true);
  //   }
  return (
    <div className='grid grid-cols-1 gap-10 lg:gap-20'>
      <div className='space-y-3 text-center flex flex-col justify-center'>
        <h1
          className='text-5xl md:text-6xl font-bold tracking-tight'
          data-aos='fade-up'
          data-aos-duration='800'
          data-aos-delay='0'
        >
          Exclusive & Diverse <br className='hidden lg:block' />
          Resell Coummunity
        </h1>
        <p
          className='text-cardText lg:w-1/2 px-3 mx-auto'
          data-aos='fade-up'
          data-aos-duration='800'
          data-aos-delay='300'
        >
          The #1 Sneaker Based Reselling group home to backdoors, the best 1 on
          1 bot support, Sports Cards, Pokemon Cards, Stocks, Crypto, Retail
          Arbitrage, Xbox/PS5. We teach people how to make money!
        </p>
        {/* <div className='space-x-3 pt-3'>
          <button
            onClick={openModal}
            className='focus:outline-none px-6 py-3 border-2 border-primary bg-primary hover:bg-opacity-25 rounded-full font-medium trans flex items-center transform active:scale-90'
          >
            Watch the Trailer&nbsp; <AiFillPlayCircle />
          </button>
        </div> */}
      </div>

      <div
        className='flex items-center justify-center lg:justify-end rounded-xl'
        data-aos='fade-up'
        data-aos-duration='800'
        data-aos-delay='600'
      >
        {/* <img src={showcase} alt='Discord Screenshot' className='w-1/3' /> */}
        <video
          src={IntroVid}
          poster={Thumbnail}
          alt='intro video to TGC'
          playsInline
          autoPlay
          loop
          muted
          className='mx-auto my-auto rounded-lg focus:outline-none lg:w-3/4 shadow trans overflow-hidden'
          width='320'
          height='240'
          controls
        ></video>
      </div>
      {/* <Transition show={open} as={Fragment}>
        <Dialog
          as='div'
          id='modal'
          className='fixed inset-0 z-10 overflow-y-auto bg-gray-900 bg-opacity-70 trans'
          initialFocus={cancelButtonRef}
          static
          open={open}
          onClose={closeModal}
        >
          <div className='min-h-screen px-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Dialog.Overlay className='fixed inset-0' />
            </Transition.Child>
            <span
              className='inline-block h-screen align-middle'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <div className='inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl'>
                <Dialog.Title
                  as='h3'
                  className='text-xl font-medium leading-6 text-gray-900 text-center'
                >
                  Trailer
                </Dialog.Title>
                <div className='mt-2'>
                  <video
                    src={IntroVid}
                    alt='intro video to TGC'
                    className='mx-auto my-auto rounded-lg focus:outline-none w-full'
                    width='320'
                    height='240'
                    auto
                    controls
                    autoplay
                  ></video>
                </div>

                <div className='mt-4 w-full flex items-center justify-center'>
                  <button
                    type='button'
                    className='px-6 py-3 border-2 border-primary bg-primary hover:bg-opacity-25 rounded-full font-medium trans transform active:scale-90 focus:outline-none mx-auto text-center'
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition> */}
    </div>
  );
};

export default Hero;
