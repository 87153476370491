import { Disclosure, Transition } from '@headlessui/react';
import { BsChevronUp } from 'react-icons/bs';

const questions = [
  {
    name: 'What does the group offer?',
    description:
      'Top tier 1 on 1 support, our very own autofill extension free for all members, free slots on almost every drop, preorders, brickseek, stocks (shares & options), crypto, collectibles/funko & other low key flips, sports & Pokémon cards info/investments, bot flipping, multiple proxy & other company partnerships, & many more!',
    AOSdelay: '0',
  },
  {
    name: 'How much is the membership & how does renewal work?',
    description:
      'Memberships are $50 a month. All memberships will automatically renew each month on the same date.',
    AOSdelay: '200',
  },
  {
    name: 'Can I cancel at any time & how would I do so?',
    description:
      'You can cancel at any point in time by going to our dashboard. ',
    AOSdelay: '400',
  },
  {
    name: 'If I cancel, can I join back at any point in time?',
    description: 'No, you will have to wait for a restock to get back in. ',
    AOSdelay: '600',
  },
  {
    name:
      'I had to transfer discord’s, how do I transfer access to the group to my new account?',
    description:
      'You can unbind/bind your membership key on our dashboard whenever you need to switch your accounts over. ',
    AOSdelay: '800',
  },
  {
    name:
      'I don’t live in the United States, is this group still worth it for me?',
    description: 'Sadly, no. We’re mainly a US based & focused group.',
    AOSdelay: '1000',
  },
  {
    name: 'Is experience & a bot necessary for me to succeed in the group?',
    description:
      'Not at all! All of us started with no experience or bots. With the help of our support team & the many different ways we provide to make money, you will be on the right track in no time!',
    AOSdelay: '1000',
  },
];

const Faq = () => {
  return (
    <div className=''>
      <div className='space-y-3 text-center flex flex-col justify-center'>
        <h2
          className='text-4xl md:text-5xl font-bold tracking-tight'
          data-aos='fade-up'
          data-aos-duration='800'
          data-aos-delay='0'
        >
          Commonly Asked Questions
        </h2>
        {/* <p
          className='text-cardText lg:w-1/3 px-3 mx-auto'
          data-aos='fade-up'
          data-aos-duration='800'
          data-aos-delay='300'
        >
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus eius
          corrupti maxime, quos molestiae aliquid voluptate ex fugit iste quis
          itaque quia quidem saepe, vitae architecto rerum ducimus delectus
          totam!
        </p> */}
      </div>
      <div className='md:w-2/3 mx-auto pt-10'>
        <div className='w-full grid grid-cols-1 gap-3'>
          {questions.map((question) => (
            <Disclosure
              key={question.name}
              data-aos='fade-up'
              data-aos-duration='800'
              data-aos-delay={question.AOSdelay}
            >
              {({ open }) => (
                <div className='bg-transparent hover:bg-card active:bg-card rounded-sm trans'>
                  <Disclosure.Button
                    className={`flex justify-between text-xl font-semibold w-full p-5 text-left focus:outline-none ${
                      open ? 'bg-card border-t-8 border-primary' : ''
                    }`}
                  >
                    <span>{question.name}</span>
                    <BsChevronUp
                      className={`${
                        open ? 'transform rotate-180 trans' : ''
                      } w-5 h-5 text-primary`}
                    />
                  </Disclosure.Button>
                  <Transition
                    show={open}
                    enter='transition duration-100 ease-out'
                    enterFrom='transform scale-95 opacity-0'
                    enterTo='transform scale-100 opacity-100'
                    leave='transition duration-75 ease-out'
                    leaveFrom='transform scale-100 opacity-100'
                    leaveTo='transform scale-95 opacity-0'
                  >
                    <Disclosure.Panel className='px-8 pt-2 pb-4 text-sm text-gray-500 bg-card'>
                      {question.description}
                    </Disclosure.Panel>
                  </Transition>
                </div>
              )}
            </Disclosure>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faq;
