const button = ({ link, text }) => {
  return (
    <a
      className='px-6 py-3 border-2 border-primary bg-primary hover:bg-opacity-25 rounded-full font-medium trans transform active:scale-90'
      href={link}
    >
      {text}
    </a>
  );
};

export default button;
